<template>
    <div class="benefits full-width" v-if="translates && translates[langUrl]">
        <div
            class="benefits__main-image">
            <div class="benefits__main-image__opacity"></div>
            <div class="benefits__main-image__container">
                <div class="benefits__main-image__container__text">
                    <div class="benefits__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                </div>
            </div>
        </div>
        
        <div class="benefits__main-container">
            <div class="benefits__block">
                <div class="benefits__block__text-block">
                    <div class="benefits__block__text-block__text">
                        <div class="benefits__block__text-block__title">{{ translates[langUrl].benefits_firstBlock_title[lang] }}</div>
                        <div class="benefits__block__text-block__description">{{ translates[langUrl].benefits_firstBlock_description[lang] }}</div>
                        <BaseButton 
                            class="button-fill benefits__block__text-block__button"
                            @click="goTo('blogger-registration')"
                        >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton>
                    </div>
                    
                </div>
                <div class="benefits__block__picture benefits__block__picture__1">
                    <div v-if="isDesktop" class="benefits__block__picture-text-block">
                        <div class="benefits__block__picture-text-block__text">{{ translates[langUrl].benefits_firstBlock_text[lang] }}</div>
                    </div>
                </div>
                <div v-if="!isDesktop" class="benefits__block__picture-text-block_mobile">
                    <div class="benefits__block__picture-text-block__text">{{ translates[langUrl].benefits_firstBlock_text[lang] }}</div>
                </div>
            </div>
            <div class="benefits__block">
                <div class="benefits__block__text-block">
                    <div v-if="isDesktop" class="benefits__block__text-block__text">
                        <div class="benefits__block__text-block__description" v-html="translates[langUrl].benefits_2_text[lang]"></div>
                    </div>
                </div>
                <div class="benefits__block__picture benefits__block__picture__2"></div>
                <div v-if="!isDesktop" class="benefits__block__text-block__text benefits__block__text-block__text-first">
                    <div v-if="!isDesktop" class="benefits__block__text-block__description" v-html="translates[langUrl].benefits_2_text[lang]"></div>
                </div>
            </div>
            <div class="benefits__block">
                <div class="benefits__block__text-block">
                    <div v-if="isDesktop" class="benefits__block__text-block__text">
                        <div class="benefits__block__text-block__description" v-html="translates[langUrl].benefits_3_text[lang]"></div>
                        <BaseButton
                            class="button-fill benefits__block__text-block__button"
                            @click="goTo('blogger-registration')"
                        >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton>
                    </div>
                </div>
                <div class="benefits__block__picture benefits__block__picture__3"></div>
                <div v-if="!isDesktop" class="benefits__block__text-block__text benefits__block__text-block__text-first">
                        <div class="benefits__block__text-block__description" v-html="translates[langUrl].benefits_3_text[lang]"></div>
                        <BaseButton
                            class="button-fill benefits__block__text-block__button"
                            @click="goTo('blogger-registration')"
                        >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton>
                    </div>
            </div>
        </div>
        <div class="benefits__main-container__second">
            <div class="benefits__main-container__second__container">
                <div class="benefits__main-container__second__title">{{ translates[langUrl].secondBlock_title[lang] }}</div>
                <div class="benefits__block benefits__block__second">
                    <div class="benefits__block__text-block">
                        <div class="benefits__block__text-block__text">
                            <ul class="benefits__block__text-block__list" v-html="translates[langUrl].benefits_5_text[lang]">
                                <!-- <li>Установите значимые связи с вашим сообществом.</li>
                                <li class="benefits__block__text-block__point">Получайте постоянный доход, путешествуя по миру!</li>
                                <li class="benefits__block__text-block__point">Насладитесь путешествием и приемом гостей без стресса — в каждой поездке у вас будет сертифицированный гид. 
                                    При этом все мероприятия и условия проживания будут тщательно проверены, так что вы можете просто приехать и стать отличным хозяином в своем путешествии. 
                                    Другими словами, вы просто сами создаете себя.
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="benefits__block__picture benefits__block__picture__4"></div>
                </div>
                <div class="benefits__block benefits__block__second">
                    <div class="benefits__block__text-block">
                        <div class="benefits__block__text-block__text">
                            <ul class="benefits__block__text-block__list" v-html="translates[langUrl].benefits_6_text[lang]">
                                <!-- <li class="benefits__block__text-block__point">Проявите себя как великолепный лидер в своем пространстве. 
                                    Предлагайте офлайн семинары и готовьтесь только к тому, чему вы будете учить — мы подготовим выделенное пространство, а также поможем организовать семинары на открытом воздухе в лучших локациях региона.
                                </li>
                                <li class="benefits__block__text-block__point">Создавайте великолепный, уникальный контент, который выделит ваш бренд среди остальных и поможет привлечь новых партнеров.</li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="benefits__block__picture benefits__block__picture__5"></div>
                </div>
                <BaseButton
                    class="button-fill benefits__main-container__button"
                    @click="goTo('blogger-registration')"
                >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton>
            </div>
        </div>
        <div class="benefits__expert">
            <div class="benefits__expert__title">{{ translates[langUrl].expert_blockTitle[lang] }}</div>
            <div class="benefits__expert__description">{{ translates[langUrl].expert_blockDescription[lang] }}</div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item1_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item1_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item2_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item2_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item3_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item3_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item4_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item4_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item5_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item5_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item6_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item6_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item7_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item7_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item8_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item8_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item9_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item9_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item10_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item10_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item11_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item11_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item12_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item12_description[lang] }}</div>
            </div>
            <div class="benefits__expert__item">
                <div class="benefits__expert__item__title">{{ translates[langUrl].expert_item13_title[lang] }}</div>
                <div class="benefits__expert__item__text">{{ translates[langUrl].expert_item13_description[lang] }}</div>
            </div>
        </div>
        <div class="benefits__pink-block">
            <div class="benefits__pink-block__icon"></div>
            <div class="benefits__pink-block__text">{{ translates[langUrl].pinkBlock_versus[lang] }}</div>
        </div>
        <div class="benefits__versus">
            <div class="benefits__versus__title">{{ translates[langUrl].versus_title[lang] }}</div>
            <BaseButton
                class="button-fill benefits__versus__button"
                @click="goTo('blogger-registration')"
            >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton>
            <div v-if="!isDesktop" class="benefits__versus__spreadsheet__buttons">
                <div 
                    @click="hosting" 
                    class="benefits__versus__spreadsheet__buttons__button"
                    :class="isHosting ? 'benefits__versus__spreadsheet__buttons__button_active' : ''"
                >{{ translates[langUrl].versus_host[lang] }}</div>
                <div 
                    @click="notHosting" 
                    class="benefits__versus__spreadsheet__buttons__button"
                    :class="!isHosting ? 'benefits__versus__spreadsheet__buttons__button_active' : ''"
                >{{ translates[langUrl].versus_selfMade[lang] }}</div>
            </div>
            <div class="benefits__versus__spreadsheet">
                <div v-if="isDesktop" class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text"></div>
                    <div class="benefits__versus__spreadsheet__line__host">{{ translates[langUrl].versus_host[lang] }}</div>
                    <div class="benefits__versus__spreadsheet__line__other">{{ translates[langUrl].versus_selfMade[lang] }}</div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line1[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other"></div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line2[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line3[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line4[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other"></div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line5[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other"></div>
                </div>
                <!-- <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">Алгоритм планирования путешествия</div>
                    <div class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div> -->
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line6[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other"></div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line7[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line8[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line9[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line10[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line11[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other"></div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line12[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other"></div>
                </div>
                <div class="benefits__versus__spreadsheet__line">
                    <div class="benefits__versus__spreadsheet__line__text">{{ translates[langUrl].spreadsheet_line13[lang] }}</div>
                    <div v-if="isHosting" class="benefits__versus__spreadsheet__line__host">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                    <div v-if="!isHosting" class="benefits__versus__spreadsheet__line__other">
                        <img src="../assets/CheckMarkPink.svg" style="height: 25px; width: 25px"/>
                    </div>
                </div>
            </div>
            <BaseButton
                class="button-fill benefits__versus__button"
                @click="goTo('blogger-registration')"
            >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS } from '@/vars';

    import BaseButton from '../components/common/BaseButton.vue';
    import MobilePreview from '../components/common/trip/PreviewCard';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'Benefits',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle ? this.translates[this.langUrl].browserTitle[this.lang]: ''
            }
        },
        components: {
            BaseButton,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            isHosting: true,
            langUrl: '/benefits'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            this.isDesktop = !isMobile();
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            hosting() {
                this.isHosting = true
            },
            notHosting() {
                this.isHosting = false
            }
        },
    };
</script>

<style lang="scss">
.benefits {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/benefits/head.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 80px;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
            margin-bottom: 60px;
        }
        // &__opacity {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background: #543919;
        //     opacity: 0.5;
        // }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 254px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1160px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    margin-top: 115px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__pink-block {
        width: 100%;
        height: 300px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        @media all and (max-width: 768px) {
            height: 225px;
            margin-bottom: 60px;
        }
        &__icon {
            width: 55px;
            height: 55px;
            background: url(../assets/benefits/icon.png);
            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 52px;
            line-height: 63px;
            color: #FFFFFF;
            text-align: center;
            @media all and (max-width: 768px) {
                font-size: 24px;
                line-height: 130%;
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
        &__second {
            padding: 80px 0;
            background: #F6F7F9;
            @media all and (max-width: 768px) {
                padding: 60px 0 20px;
            }
            &__title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                text-align: center;
                color: #273155;
                margin-bottom: 80px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 60px;
                }
            }
            &__container {
                max-width: 1280px;
                margin: 0 auto;
                @media all and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &__button {
            margin-left: calc(50% - 97px);
            @media all and (max-width: 768px) {
                margin: 0 auto;
            }
        }
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
        &__second {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 80px;
            &:nth-of-type(2n) {
                display: flex;
                flex-direction: row;
            }
        }
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 50px;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__text-block {
            display: flex;
            flex-direction: row;
            max-width: 500px;

            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                flex-direction: column;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
                margin: 0 20px;
                &-first{
                    @media all and (max-width: 768px) {
                        width: calc(100% - 40px);
                    }
                }
            }
            &__title {
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 130%;
                }
            }
            &__description {
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
            ul > li {
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                color: #3A4256;
                margin-bottom: 16px;
                list-style: none;
                &:before {
                    content: '✔';
                    color: #8ACCDF;
                    margin: 0 10px 0 -24px;
                }
            }
            // &__point {
            //     font-weight: 400;
            //     font-size: 18px;
            //     line-height: 160%;
            //     color: #3A4256;
            //     margin-bottom: 16px;
            //     list-style: none;
            //     &:before {
            //         content: '✔';
            //         color: #8ACCDF;
            //         margin: 0 10px 0 -24px;
            //     }
            // }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    margin: 0 auto 40px;
                    width: calc(100% - 40px);
                }
            }
        }
        &__button {
            margin: 0 auto 0 0;
        }
        &__picture {
            min-width: 600px;
            height: 430px;
            background-size: 110%;
            background-position: center;
            border-radius: 10px;
            position: relative;
            @media all and (max-width: 1200px) {
                min-width: 500px;
                height: 355px;
            }
            @media all and (max-width: 950px) {
                min-width: 400px;
                height: 290px;
            }
            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
                height: 220px;
                margin-bottom: 40px;
            }
            &__1 {
                background-image: url(../assets/benefits/1.jpg);
                margin-bottom: 0;
                border-radius: 10px 10px 0 0;
            }
            &__2 {
                background-image: url(../assets/benefits/2.jpg);
            }
            &__3 {
                background-image: url(../assets/benefits/3.jpg);
            }
            &__4 {
                background-image: url(../assets/benefits/4.jpg);
            }
            &__5 {
                background-image: url(../assets/benefits/5.jpg);
            }
            &-text-block {
                position: absolute;
                right: -90px;
                top: 60px;
                width: 400px;
                background: #FFFFFF;
                box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
                border-radius: 12px;
                padding: 28px;
                @media all and (max-width: 1420px) {
                    right: 20px;
                }
                &_mobile {
                    box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
                    border-radius: 0 0 12px 12px;
                    margin-bottom: 60px;
                    padding: 20px;
                    margin: 0 20px;
                }
                &__text {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    color: #616D89;
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 140%;
                    }
                }
            }
        }
    }
    &__expert {
        max-width: 1280px;
        margin: 80px auto 170px;
        @media all and (max-width: 768px) {
            margin: 60px auto;
            padding: 0 20px;
        }
        &__title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 32px;
            max-width: 1000px;
            @media all and (max-width: 768px) {
                font-size: 28px;
                line-height: 34px;
            }
        }
        &__description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #273155;
            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 140%;
            }
        }
        &__item {
            font-family: 'Inter';
            font-style: normal;
            margin-top: 100px;
            color: #273155;
            @media all and (max-width: 768px) {
                margin-top: 60px;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                margin-bottom: 24px;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    line-height: 140%;
                }
            }
            &__text {
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }
    }
    &__versus {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            text-align: center;
            color: #273155;
            margin-bottom: 32px;
            @media all and (max-width: 768px) {
                font-size: 28px;
                line-height: 34px;
                margin-bottom: 20px;
            }
        }
        &__button {
            margin: 0 auto;
            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
            }
        }
        &__spreadsheet {
            margin: 60px auto 40px;
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            border-radius: 12px;
            width: 1280px;
            
            @media all and (max-width: 1280px) {
                width: 1024px;
            }

            @media all and (max-width: 1024px) {
                width: 768px;
            }

            @media all and (max-width: 768px) {
                padding: 0 20px;
                margin: 40px 0;
                width: 100%;
            }
            &__buttons {
                width: calc(100% - 40px);
                display: flex;
                flex-direction: row;
                &__button {
                    display: flex;
                    width: 50%;
                    height: 90px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    border-bottom: 1px #E7EAEF solid;
                    &_active {
                        color: #E61E4D;
                        border-bottom: 1px #E61E4D solid;
                        transition-duration: 1s;
                    }
                }
            }
            
            &__line {
                height: 100px;
                display: flex;
                flex-direction: row;
                border-top: 0.3px #dadada69 solid;
                &:first-of-type {
                    border-top: none;
                }
                @media all and (max-width: 768px) {
                    height: max-content;
                }
                &__text {
                    height: 100%;
                    width: 36%;
                    padding: 10px 32px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-right: 0.3px #dadada69 solid;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    color: #273155;
                    @media all and (max-width: 768px) {
                        height: max-height;
                        width: 200px;
                        padding: 20px;
                        font-size: 16px;
                    }
                }
                &__host {
                    height: 100%;
                    width: 32%;
                    background: #FAFAFA;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 0.3px #dadada69 solid;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 140%;
                    text-align: center;
                    color: #273155;
                    @media all and (max-width: 768px) {
                        height: auto;
                        width: calc(100% - 200px);
                        font-size: 18px;
                        border-right: none;
                    }
                }
                &__other {
                    height: 100%;
                    width: 32%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 140%;
                    text-align: center;
                    color: #273155;
                    @media all and (max-width: 768px) {
                        height: auto;
                        width: calc(100% - 200px);
                        font-size: 18px;
                        background: #FAFAFA;
                    }
                }
            }
        }
    }
}

</style>